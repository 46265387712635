/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'easel-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.473.337a.5.5 0 00-.946 0L6.954 2H2a1 1 0 00-1 1v7a1 1 0 001 1h1.85l-1.323 3.837a.5.5 0 10.946.326L4.908 11H7.5v2.5a.5.5 0 001 0V11h2.592l1.435 4.163a.5.5 0 00.946-.326L12.15 11H14a1 1 0 001-1V3a1 1 0 00-1-1H9.046z"/>',
    },
});
